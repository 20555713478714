import React from "react";
import Container from "./container";
import { cn } from "../lib/helpers";
import GlossaryItem from "./glossary-item";

import * as styles from "../styles/components/glossary-list.module.css";

const GlossaryList = (props) => {

  const {
    glossary
  } = props;

  let groupFirstChar;
  let showFirstChar = false;

  return (
    <div className={styles.root}>
      <Container>
        <div className="grid">
          {glossary.map(({node: glossary}) => {

            const itemFirstChar = Array.from(glossary.title)[0].toUpperCase();

            if(groupFirstChar === itemFirstChar) {
              showFirstChar = false;
            }
            else {
              groupFirstChar = itemFirstChar;
              showFirstChar = true;
            }

            return (
              <>
                {showFirstChar && (
                  <div className={cn("col-12 col-xs-6 col-md-4", styles.item)}>
                    <div className={cn("h1", styles.firstChar)}><span>{groupFirstChar}</span></div>
                  </div>
                )}
                <div key={glossary.id} className={cn("col-12 col-xs-6 col-md-4", styles.item)}>
                  <GlossaryItem glossary={glossary} />
                </div>
              </>
            )
          })}
        </div>
      </Container>
    </div>
  );
}

export default GlossaryList;
