import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";
import { Helmet } from 'react-helmet';
import { toPlainText } from "../lib/helpers";

import PageHeader from "../components/post-landing-page-header";
import GlossaryList from "../components/glossary-list";
import CallToAction from "../components/call-to-action";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query GlossaryLandingQuery {
    glossaryLanding: sanityGlossaryLanding {
      title
      themePurple
      _rawDescription
      callToAction {
        title
        _rawDescription
        button {
          blank
          secondaryButton
          text
          url
        }
      }
      seo {
        title
        description
        thumbnail {
          asset {
            _id
          }
          alt
        }
      }
    }

    glossary: allSanityGlossary(
      sort: { fields: [title], order: ASC }
      filter: { slug: { current: { ne: null } } }
    ) {
      edges {
        node {
          id
          title
          slug {
            current
          }
        }
      }
    }
  }
`;

const GlossaryLandingPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const glossaryLanding = (data || {}).glossaryLanding;
  const glossary = (data || {}).glossary.edges;

  const title = glossaryLanding.seo?.title || glossaryLanding.title;
  const description = glossaryLanding.seo?.description || toPlainText(glossaryLanding._rawDescription);
  const image = glossaryLanding.seo?.thumbnail;
  const autoSiteTitle = !glossaryLanding.seo?.title;

  return (
    <Layout>
      <Seo
        title={title}
        description={description}
        image={image}
        autoSiteTitle={autoSiteTitle}
      />

      <Helmet>
        <html className={glossaryLanding.themePurple ? "theme-purple" : undefined} lang="en" />
      </Helmet>

      {glossaryLanding.title && (<PageHeader title={glossaryLanding.title} description={glossaryLanding._rawDescription} />)}
      {glossary && (<GlossaryList glossary={glossary} />)}
      {glossaryLanding.callToAction && (<CallToAction {...glossaryLanding.callToAction} />)}
    </Layout>
  );
};

export default GlossaryLandingPage;
