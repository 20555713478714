import React from "react";
import { cn, getGlossaryUrl } from "../lib/helpers";
import { Link } from "gatsby";

import * as styles from "../styles/components/glossary-item.module.css";

const GlossaryItem = (props) => {

  const {
    glossary
  } = props;

  return (
    <div className={styles.root}>
      {glossary.title && (
        <Link className={styles.link} to={getGlossaryUrl(glossary.slug)}><span>{glossary.title}</span></Link>
      )}
    </div>
  );
}

export default GlossaryItem;
